import { useState, useEffect, useContext, useMemo } from "react";
import { ROUTES } from "../../_routes";
import { UserContext } from "../../_context/UserContext";
import { useOrgProFeatures } from "../../_context/OrgProFeaturesContext";

export const useGetMenuItems = (menu) => {
  const { userData, plnData, loading } = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [isPssOnly, setIsPssOnly] = useState(false);
  const { isAdmin, isBeta, isInstructor, isManager, isOwner, isSubuser, isUser } = userData;
  const isDirectUser = userData?.organization?.id === 1;

  const isChallengesFeature = userData?.features?.challenges;
  const isTeamChallengesFeature = userData?.features?.teamChallenges;
  const isProProgramsFeature = userData?.features?.pssprograms;
  const hasHomeworksFeature = userData?.features?.homeworks;
  const hasLoyaltyFeature = userData?.features?.loyalty;

  // Org Pro Features Check
  const { orgPaidFeatures } = useOrgProFeatures();
  const { orgHasPlusFeature, orgHasSubBAPlusFeature } = orgPaidFeatures;
  const userCanBuyBAPlus = orgHasPlusFeature && !isSubuser;
  const subuserCanBuyBAPlus = orgHasSubBAPlusFeature && isSubuser;

  // Check user access, display label based on user access
  const hasActivePLN = useMemo(() => Boolean(plnData.user.data?.data?.pln?.id), [
    plnData.user.data?.data,
  ]);
  const hasPrimePlan = useMemo(
    () =>
      userData?.new_subscription?.find(
        (plan) => plan?.plan_type === "plus" || plan?.plan_type === "bundle"
      ),
    [userData?.new_subscription]
  );

  const userHasActivePlusSub = hasPrimePlan || hasActivePLN;

  useEffect(() => {
    if ((isOwner || isInstructor || isManager) && !isUser) {
      setIsPssOnly(true);
    }
  }, [isInstructor, isManager, isOwner, isUser]);

  useEffect(() => {
    if (menu && !loading) {
      switch (menu) {
        case "classes":
          setItems([
            {
              label: "Recommendations",
              link: `${ROUTES.COURSES}${ROUTES.RECOMMENDATIONS}`,
            },
            {
              label: "Categories",
              link: `${ROUTES.COURSES}${ROUTES.CATEGORIES}`,
            },
            {
              label: "Most Popular",
              link: `${ROUTES.COURSES}${ROUTES.POPULAR}`,
            },
            {
              label: "New Classes",
              link: `${ROUTES.COURSES}${ROUTES.NEW}`,
            },
            {
              label: "Private Live Classes",
              link: `${ROUTES.COURSES}${ROUTES.PRIVATE}/live`,
              hide: !userData?.has_live,
            },
            {
              label: "Private Live Open Door Classes",
              link: `${ROUTES.COURSES}${ROUTES.PRIVATE}${ROUTES.LIVE_OPEN_DOOR}`,
              hide: !userData?.has_live,
            },
            {
              label: "Private Video Classes",
              link: `${ROUTES.COURSES}${ROUTES.PRIVATE}`,
              hide: !userData?.has_private,
            },
            {
              label: "Partnership Channel",
              link: `${ROUTES.COURSES}${ROUTES.PARTNERSHIPS}`,
              hide: !userData?.has_partnership,
            },
            {
              label: "Partner Directory",
              link: ROUTES.PARTNERS,
            },
            {
              label: "Instructor Directory",
              link: ROUTES.INSTRUCTORS,
            },
            {
              label: "Burnalong Calendar",
              link: ROUTES.CALENDAR,
            },
          ]);
          break;
        case "program":
          setItems([
            {
              label: "Instructor Programs",
              link: ROUTES.PROGRAMS,
            },
            {
              label: "User Playlists",
              link: `${ROUTES.PLAYLIST}${ROUTES.ALL}`,
            },
          ]);
          break;
        case "progress-steps":
          setItems([
            {
              label: "All Progress",
              link: ROUTES.PROGRESS,
            },
            {
              label: "Achievements",
              link: ROUTES.ACHIEVEMENTS,
            },
            {
              label: "Points History",
              link: ROUTES.POINTS_HISTORY,
            },
          ]);
          break;
        case "profile":
          setItems([
            {
              label: "Individual Challenges",
              link: ROUTES.CHALLENGES,
              hide: !(isBeta || isChallengesFeature),
            },
            {
              label: "Team Challenges",
              link: ROUTES.TEAM_CHALLENGES,
              hide: !(isBeta || isTeamChallengesFeature),
            },
            {
              label: "Events",
              link: ROUTES.EVENTS,
            },
            {
              label: "Interests",
              link: ROUTES.INTERESTS,
            },
            {
              label: "Wellness Habits",
              link: ROUTES.WELLNESS_HABITS,
            },
            {
              line: true,
            },
            {
              label: "My Contacts",
              link: ROUTES.CONTACTS,
            },
            {
              label: "My Playlists",
              link: ROUTES.PLAYLIST,
            },
            {
              label: "My Schedule",
              link: ROUTES.SCHEDULE,
            },
            {
              label: "My History",
              link: ROUTES.HISTORY,
            },
            {
              label: "Favorites",
              link: ROUTES.FAVORITES,
            },
            {
              line: true,
            },
            {
              label: "Profile",
              link: ROUTES.PROFILE,
            },
            {
              label: "Homework",
              link: `${ROUTES.PROFILE}${ROUTES.HOMEWORK}`,
              hide: !hasHomeworksFeature,
            },
            {
              label: "Goals",
              link: ROUTES.WELLNESS_GOALS,
            },
            {
              label: "Medical Conditions",
              link: ROUTES.CONDITIONS,
            },
            {
              line: true,
            },
            {
              label: "Settings",
              link: ROUTES.SETTINGS,
            },
            {
              label: "Sub Accounts",
              link: ROUTES.SUB_ACCOUNTS,
              hide: isSubuser || isDirectUser,
            },
            {
              line: true,
            },
            {
              label: "Help",
              href: "https://www.burnalong.com/contact-us/",
            },
          ]);
          break;
        case "main":
          setItems([
            {
              label: "Home",
              icon: "home",
              link: ROUTES?.DASHBOARD,
            },
            {
              label: "Classes",
              icon: "classes",
              link: null,
              showMore: true,
            },
            {
              label: "Programs",
              icon: "program",
              showMore: true,
            },
            {
              label: "Progress",
              icon: "progress-steps",
              showMore: true,
              hide: !hasLoyaltyFeature,
            },
            {
              label: "Communities",
              icon: "user-social",
              link: ROUTES?.COMMUNITIES,
            },
            {
              label: userHasActivePlusSub ? "Participating Locations" : "Gym Upgrade",
              icon: "locations",
              link: ROUTES?.PLN,
              hide: !(userCanBuyBAPlus || subuserCanBuyBAPlus),
            },
            {
              label: "Partner Portal",
              icon: "pss",
              link: ROUTES?.PSS,
              hide: !(isOwner || isInstructor || isAdmin || isManager),
            },
            {
              label: "Profile",
              icon: "user-outline",
              link: null,
              showMore: true,
              hideOnHeader: true,
            },
          ]);
          break;
        case "pss":
          setItems([
            {
              label: "Dashboard",
              icon: "home",
              link: ROUTES?.PSS,
              id: "pss_side_menu_dashboard",
            },
            {
              label: "Classes",
              icon: "classes",
              link: `${ROUTES?.PSS}${ROUTES?.COURSES}`,
              id: "pss_side_menu_classes",
            },
            {
              label: "Programs",
              icon: "program",
              link: `${ROUTES?.PSS}${ROUTES?.PROGRAMS}`,
              hide: !(isBeta || isProProgramsFeature),
              id: "pss_side_menu_programs",
            },
            {
              label: "Reviews",
              icon: "reviews",
              link: `${ROUTES?.PSS}${ROUTES?.REVIEWS}`,
              id: "pss_side_menu_reviews",
            },
            {
              line: true,
            },
            {
              label: "Partners",
              icon: "partners",
              link: `${ROUTES?.PSS}${ROUTES?.PARTNERS}`,
              hide: !isAdmin,
              id: "pss_side_menu_partners",
            },
            {
              label: "Locations",
              icon: "locations",
              link: `${ROUTES?.PSS}${ROUTES?.LOCATIONS}`,
              hide: !(isAdmin || isOwner),
              id: "pss_side_menu_locations",
            },
            {
              label: "Business Profile",
              icon: "profile-business",
              link: `${ROUTES?.PSS}${ROUTES?.PROFILE}${ROUTES?.BUSINESS}`,
              hide: !(isAdmin || isOwner),
              id: "pss_side_menu_business_profile",
            },
            {
              line: true,
            },
            {
              label: "Owners",
              icon: "owners",
              link: `${ROUTES?.PSS}${ROUTES?.OWNERS}`,
              hide: !(isAdmin || isManager || isOwner),
              id: "pss_side_menu_owners",
            },
            {
              label: "Instructors",
              icon: "instructors",
              link: `${ROUTES?.PSS}${ROUTES?.INSTRUCTORS}`,
              id: "pss_side_menu_instructors",
            },
            {
              label: "User Profile",
              icon: "user-outline",
              link: `${ROUTES?.PSS}${ROUTES?.PROFILE}`,
              fill: "red",
              id: "pss_side_menu_user_profile",
            },
            {
              line: true,
            },
            {
              label: "Go to well.burnalong",
              link: `${ROUTES?.DASHBOARD}`,
              hide: isPssOnly,
              id: "pss_side_menu_well_dashboard",
            },
          ]);
          break;
        default:
          break;
      }
    }
  }, [
    isAdmin,
    isBeta,
    isChallengesFeature,
    isDirectUser,
    isInstructor,
    isManager,
    isOwner,
    isProProgramsFeature,
    isPssOnly,
    isSubuser,
    isTeamChallengesFeature,
    menu,
    userData,
    plnData,
    hasHomeworksFeature,
    userHasActivePlusSub,
    userCanBuyBAPlus,
    subuserCanBuyBAPlus,
    loading,
  ]);

  return items;
};
